import errorIcon from "../resources/images/FlowIcons/error-icon.svg";
import {FormattedMessage} from "react-intl";

const UpcGameAlreadyActivated = () => {
    const openCustomerSupport = () => {
        window.open("https://www.ubisoft.com/help", "_blank");
    };

    const openHelpArticle = (articleId: string) => {
        window.open(`https://www.ubisoft.com/help/article/${articleId}`, "_blank");
    };

    return (
        <div className="error">
            <img className="icon" src={errorIcon} alt="Error" />
            <h2><FormattedMessage id="page.error.activation.title" /></h2>
            <p><FormattedMessage id="page.error.activation.description" /></p>
            <button className="btn" onClick={() => openHelpArticle('000100615')}><FormattedMessage id="button.read_faq" /></button>&nbsp;
            <button className="btn" onClick={openCustomerSupport}><FormattedMessage id="button.cta.contact_support" /></button>
        </div>
    );
}

export default UpcGameAlreadyActivated;