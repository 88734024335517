export const loadScript = (url: string, timeoutMs: number = 10000): Promise<void> => {
    return new Promise((resolve, reject) => {
        let script: HTMLScriptElement = document.createElement("script");
        let scripts = document.getElementsByTagName("script");
        let prior = scripts[scripts.length - 1];
        script.async = true;
        prior.parentNode && prior.parentNode.insertBefore(script, prior);

        let resolved = false;
        let timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
            if (!resolved) {
                reject();
            }
        }, timeoutMs);

        script.onload = () => {
            resolved = true;
            clearTimeout(timeout);
            resolve();
        };

        script.src = url;
    });
};