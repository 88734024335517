import React, {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "../context/UserContext";
import {LocaleContext} from "../context/LocaleContext";
import {PlatformContext} from "../context/PlatformContext";
import Accounts from "../containers/Accounts";
import Loading from "../components/Loading";
import GamePassService from "../data/services/GamePassService";
import {AxiosError, AxiosResponse} from "axios";
import {useSearchParams} from "react-router-dom";
import UpcMissingGamePass from "../components/UpcMissingGamePass";
import {gameMappings} from "../data/types/Game";
import UpcErrorRetry from "../components/UpcErrorRetry";
import Error from "../components/Error";
import useNavigateParams from "../hooks/useNavigateParams";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import {profilePlatformTypes} from "../data/types/UserData";
import ErrorCountry from "../components/ErrorCountry";

const ERROR_CODE_MISSING_XBOX_PROFILE = 400002;
const ERROR_CODE_XBOX_REAUTHENTICATE = 400005;
const ERROR_CODE_INVALID_COUNTRY = 400006;

const UpcFlow = () => {
    const userContext = useContext(UserContext);
    const localeContext = useContext(LocaleContext);
    const platformContext = useContext(PlatformContext);
    const navigateParams = useNavigateParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams) {
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [hasValidGamePass, setHasValidGamePass] = useState<boolean>(false);
    const [isInvalidCountry, setIsInvalidCountry] = useState<boolean>(false);
    const [xboxProfile, setXboxProfile] = useState<UbisoftProfile>();

    useEffect(() => {
        const xboxProfile = userContext.user.profiles.find((profile: UbisoftProfile) => profile.platformType === profilePlatformTypes.xbox);

        if (xboxProfile) {
            setXboxProfile(xboxProfile);
        }
    }, [userContext.user.profiles]);

    const validateUser = useCallback(() => {
        if (userContext.user.ticketData) {
            GamePassService.validateUser(userContext.user.ticketData.ticket).then(() => {
                setHasValidGamePass(true);
                setLoaded(true);
            }).catch((error: AxiosError) => {
                if (error.response?.data.errorCode === ERROR_CODE_MISSING_XBOX_PROFILE
                    || error.response?.data.errorCode === ERROR_CODE_XBOX_REAUTHENTICATE) {
                    navigateParams(`/upc/${localeContext.locale}/reverse`, {
                        game: platformContext.game || '',
                        'upc-locale': localeContext.locale
                    });
                } else {
                    setLoaded(true);
                }
            });
        }
    }, [userContext.user.ticketData]);

    const validateOwnership = useCallback(() => {
			if (userContext.user.ticketData && platformContext.game) {
				GamePassService.checkOwnership(
					userContext.user.ticketData.ticket,
					// @ts-ignore
					gameMappings[platformContext.game]
				)
					.then((result: AxiosResponse) => {
						if (result.data.ownership) {
							if (platformContext.game) {
								window.open(
									`uplay://open/game/${
										gameMappings[platformContext.game]
									}?subsection=overview`,
									"_self"
								);

								setTimeout(() => {
									if (platformContext.game) {
										window.open(
											`uplay://launch/${gameMappings[platformContext.game]}`,
											"_self"
										);
									}
								}, 1500);
							}
						} else {
							validateUser();
						}
					})
					.catch((error: AxiosError) => {
						console.log(error.response?.data);
						if (error.response?.data.errorCode === ERROR_CODE_INVALID_COUNTRY) {
							setIsInvalidCountry(true);
							setHasError(true);
						} else {
							setHasError(true);
						}
					});
			}
		}, [userContext.user.ticketData, validateUser, platformContext.game]);

    const retryFlow = () => {
        setHasError(false);
        setHasValidGamePass(false);
        setLoaded(false);
        validateOwnership();
    };

    useEffect(() => {
        if (userContext.authVerified) {
            if (userContext.user.isAuthenticated) {
                validateOwnership();
            } else {
                setLoaded(true);
                setHasError(true);
            }
        }
    }, [userContext.authVerified, userContext.user.isAuthenticated, platformContext.platform, validateOwnership]);

    if (
			(hasError &&
				userContext.authVerified &&
				!userContext.user.isAuthenticated) ||
			!platformContext.game ||
			!gameMappings[platformContext.game]
		) {
			return <Error />;
		} else if (
			hasError &&
			isInvalidCountry &&
			userContext.authVerified &&
			userContext.user.isAuthenticated
		) {
			return <ErrorCountry />;
		} else if (
			hasError &&
			userContext.authVerified &&
			userContext.user.isAuthenticated
		) {
			return <UpcErrorRetry retryFlow={() => retryFlow()} />;
		} else if (loaded && userContext.authVerified && hasValidGamePass) {
			return <Accounts />;
		} else if (loaded && userContext.authVerified && !hasValidGamePass) {
			return (
				<UpcMissingGamePass
					xboxProfile={xboxProfile}
					retryFlow={() => retryFlow()}
				/>
			);
		} else {
			return <Loading />;
		}
};

export default UpcFlow;