import React, {useCallback, useEffect} from "react";

const ConnectCallback = () => {
    const triggerExternalLogin = useCallback(() => {
        if (window.opener != null) {
            if (window.opener.overlayWidget != null) {
                window.opener.postMessage("EXTERNAL_UBISOFT_CONNECT");
            } else {
                window.opener.location.reload();
            }
            window.close();
        }
    }, []);

    useEffect(() => {
        triggerExternalLogin();
    }, [triggerExternalLogin]);

    return <h1 className="h3" style={{color: "#fff", textAlign: "center"}}>Loading...</h1>;
}

export default ConnectCallback;