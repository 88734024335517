import ubilogo from "../resources/images/logos/ubi-logo.svg";
import xboxLogo from "../resources/images/logos/xbox-logo.svg";
import esrbLogoMature from "../resources/images/logos/esrb-mature.svg";
import esrbLogoTeen from "../resources/images/logos/esrb-teen.svg";
import pegi16Logo from "../resources/images/logos/pegi16.svg";
import pegi12Logo from "../resources/images/logos/pegi12.svg";
import pegi18Logo from "../resources/images/logos/pegi18.svg";
import {FormattedMessage} from "react-intl";
import { useContext } from "react";
import { PlatformContext } from "../context/PlatformContext";
import { gameMappings } from "../data/types/Game";

const UpcFooter = () => {
	const platformContext = useContext(PlatformContext);
	const ratingLogos = {
		r6e: [pegi16Logo, pegi18Logo, esrbLogoMature],
		r6s: [pegi16Logo, pegi18Logo, esrbLogoMature],
		acor: [pegi18Logo, esrbLogoMature],
		fh: [pegi18Logo, esrbLogoMature],
		fc5: [pegi18Logo, esrbLogoMature],
		wd2: [pegi18Logo, esrbLogoMature],
		grw: [pegi18Logo, esrbLogoMature],
		ifr: [pegi12Logo, esrbLogoTeen],
	};

	const getRatingLogos = () => {
		if (platformContext.game) {
			return ratingLogos[platformContext.game].map((rating, i) => (
				<img src={rating} alt="rating" key={i} />
			));
		}
	};

	return (
		<footer className="upc">
			<div className="footer-container">
				<div className="brand-logos">
					<img src={ubilogo} alt="Ubisoft Logo" />
					<img
						src={xboxLogo}
						alt="PC Gamepass Logo"
						style={{ width: "6rem" }}
					/>
				</div>

				{platformContext.game && gameMappings[platformContext.game] && (
					<>
						<p className="ratings-text">
							<FormattedMessage
								id={`footer.rating`}
								values={{
									gamename: (
										<FormattedMessage id={`game.${platformContext.game}`} />
									),
								}}
							/>
						</p>

						<div className="pegi-logos">{getRatingLogos()}</div>
					</>
				)}

				<nav className="footer">
					<a
						href="https://www.ubisoft.com/help"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.link.support" />
					</a>
					<a
						href="https://legal.ubi.com/termsofuse"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.link.tos" />
					</a>
					<a
						href="https://legal.ubi.com/LegalInfoUbisoftEMEA"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.link.legal" />
					</a>
					<a
						href="https://legal.ubi.com/privacypolicy"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.link.privacy" />
					</a>
				</nav>

				<p className="copyright">
					<FormattedMessage id="footer.rights.notice" />
				</p>
			</div>
		</footer>
	);
};

export default UpcFooter;