import { Navigate, Outlet, useParams } from "react-router-dom";
import { Locale } from "../data/types/Locale";
import { IntlProvider } from "react-intl";
import LocaleProvider from "../context/LocaleContext";
import PlatformProvider from "../context/PlatformContext";
import UserProvider from "../context/UserContext";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import locales from "../resources/locales";
import { PLATFORM_WEB } from "../data/types/Platform";

const Main = () => {
	const params = useParams();

	const getTranslations = (locale: Locale): Record<string, string> => {
		return Object.assign({}, locales["en-us"], locales[locale]);
	};

	let locale: Locale = (params.locale as Locale) || null;
	let translations = getTranslations(locale);
	let urlPathname = window.location.pathname.replace(
		locale,
		locale.toLowerCase()
	);

	if (Object.keys(locales).indexOf(locale) < 0) {
		return <Navigate to={`/`} />;
	}

	if (locale.toLowerCase() !== locale) {
		return <Navigate to={`${urlPathname}`} />;
	}

	return (
		<PlatformProvider platform={PLATFORM_WEB}>
			<IntlProvider
				locale={locale}
				defaultLocale={process.env.REACT_APP_DEFAULT_LOCALE}
				messages={translations}
			>
				<LocaleProvider locale={locale}>
					<UserProvider>
						<Navbar />
						<div className="wrapper web">
							<div className="container">
								<Header />
								<section className="flow-container">
									<Outlet />
								</section>
							</div>
						</div>
						<Footer />
					</UserProvider>
				</LocaleProvider>
			</IntlProvider>
		</PlatformProvider>
	);
};

export default Main;
