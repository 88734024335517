import { UbisoftProfile } from "../data/types/UbisoftProfile";
import completeIcon from "../resources/images/FlowIcons/complete-icon.svg";
import { FormattedMessage } from "react-intl";

type Props = {
	xboxProfile: UbisoftProfile;
	ubisoftProfile: UbisoftProfile;
};

const GameAlreadyActivated = (props: Props) => {
	const openFAQ = () => {
		window.open("https://www.ubisoft.com/help/article/000100784", "_blank");
	};
	const openSupportPage = () => {
		window.open("https://www.ubisoft.com/help", "_blank");
	};

	return (
		<>
			<img className="icon" src={completeIcon} alt="Access Icon" />
			<h2>
				<FormattedMessage id="page.warning.activation.title.web" />
			</h2>
			<p>
				<FormattedMessage
					id="page.warning.activation.description.web"
					values={{
						userName: <b>{props.ubisoftProfile.nameOnPlatform}</b>,
						xboxUserName: <b>{props.xboxProfile.nameOnPlatform}</b>,
					}}
				/>
			</p>
			<div className="twoButtons">
				<button className="btn" onClick={openFAQ}>
					<FormattedMessage id="button.cta.visit_help" />
				</button>
				<button className="btn" onClick={openSupportPage}>
					<FormattedMessage id="button.cta.contact_support" />
				</button>
			</div>
		</>
	);
};

export default GameAlreadyActivated;
