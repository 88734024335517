export const GAME_R6_SIEGE = 'r6s';
export const GAME_R6_EXTRACTION = 'r6e';
export const GAME_AC_ORIGINS = 'acor';
export const GAME_FOR_HONOR = 'fh';
export const GAME_FAR_CRY5 = 'fc5';
export const GAME_WATCH_DOGS2 = 'wd2';
export const GAME_GR_WILDLANDS = 'grw';
export const GAME_FENIX_RISING = 'ifr';

export type Game = "r6s" | "r6e" | "acor" | "fh" | "fc5" | "wd2" | "grw" | "ifr" | null;

export const gameMappings: {[game: string]: number} = {
    [GAME_R6_EXTRACTION]: 5271,
    [GAME_R6_SIEGE]: 635,
    [GAME_AC_ORIGINS]: 3539,
    [GAME_FOR_HONOR]: 569,
    [GAME_FAR_CRY5]: 1803,
    [GAME_WATCH_DOGS2]: 2688,
    [GAME_GR_WILDLANDS]: 1771,
    [GAME_FENIX_RISING]: 5405,
};
