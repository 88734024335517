import { useState } from "react";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import xboxIcon from "../resources/images/xbox-icon.svg";
import ubiIcon from "../resources/images/ubi-icon.svg";
import {FormattedMessage} from "react-intl";

interface Props {
    xboxProfile: UbisoftProfile;
    ubisoftProfile: UbisoftProfile;
    activateUser: () => void;
}

const UpcAccounts = (props: Props) => {
    const [checked, setChecked] = useState<boolean>(false);

    return (
        <>
            <h2><FormattedMessage id="page.activation.description" /></h2>
            
            <div className="flow-content accounts upc">
                <div className="profile-box">
                    <img src={xboxIcon} alt="Xbox Profile" />
                    <span className="profile-name">
                        <FormattedMessage id="page.xbox_username" values={{xboxUserName: props.xboxProfile.nameOnPlatform}}/>
                    </span>
                    <a className="link external" href="https://www.ubisoft.com/help/article/000100784" target="_blank" rel="noreferrer">
                        <FormattedMessage id="button.cta.change_account"/>
                    </a>
                </div>

                <div className="profile-box">
                    <img src={ubiIcon} alt="Ubisoft Profile" />
                    <span className="profile-name">
                        {props.ubisoftProfile.nameOnPlatform}
                    </span>
                    <a className="link external" href="https://www.ubisoft.com/help/article/000100784" target="_blank" rel="noreferrer">
                        <FormattedMessage id="button.bad_account"/>
                    </a>
                </div>

                <p className="warning"><FormattedMessage id="page.activation.confirmation.text"/></p>

                <label>
                    <input
                        type="checkbox"
                        onChange={e => setChecked(e.target.checked)}
                    />
                    <FormattedMessage id="page.activation.warning"/>
                </label>
            </div>

            <button
                className="btn"
                onClick={props.activateUser}
                disabled={!checked}
            >
                <FormattedMessage id="page.activation.confirm"/>
            </button>

        </>
    );
}

export default UpcAccounts;