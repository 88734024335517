import React from "react";
import Login from "../../components/Login";
import {Environment} from "../../data/types/Environment";

interface Props {
    environment: Environment;
    applicationId: string;
    genomeId: string;
    locale: string;
    nextUrl: string;
}

const Connect = (props: Props) => {
    const popupLogin = (): Window | null => {
        const width = 480;
        const height = 803;

        const top = (window.screen.height - height) / 2;
        const left = (window.screen.width - width) / 2;

        const { applicationId, genomeId, locale, nextUrl } = props;

        return window.open(
            `${process.env.REACT_APP_CONNECT_BASE_PATH}/xbox-external?appId=${applicationId}&genomeId=${genomeId}&lang=${locale}&nextUrl=${encodeURIComponent(nextUrl)}`,
            "_blank",
            `width=${width},height=${height},top=${top},left=${left},toolbar=no,status=no`
        );
    }

    return (
        <Login loginAction={popupLogin} />
    );
}

export default Connect;

