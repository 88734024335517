import ConnectLogo from "../resources/images/logos/ubi-connect-logo.svg";
import DownloadIcon from "./svg/DownloadIcon";
import {FormattedMessage} from "react-intl";
import {useEffect} from "react";

const Download = () => {
    const startDownload = () => {
        window.location.href = "https://ubi.li/4vxt9";
    };

    useEffect(startDownload, []);

    return (
        <div className="download-wrap">
            <div className="download-container">
                <img src={ConnectLogo} alt="Ubisoft Connect Logo" />
                <h1><FormattedMessage id="page.download.notification" /></h1>
                <p><FormattedMessage id="page.download.pending" values={{
                    htmlString: <button onClick={startDownload}>
                        <FormattedMessage id="button.cta.download" />
                    </button>
                }} /></p>
                <button className="btn-download" onClick={startDownload}>
                    <DownloadIcon />
                    <FormattedMessage id="button.cta.download" />
                </button>
            </div>

            <p className="copyright"><FormattedMessage id="footer.rights.notice" /></p>
        </div>
    );
}

export default Download;