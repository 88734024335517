import loadingIcon from "../resources/images/loading-icon.svg";
import {FormattedMessage} from "react-intl";

const Loading = () => {
    return (
			<div className="loading">
				<img className="loading" src={loadingIcon} alt="Loading..." />
				<h2>
					<FormattedMessage id="loading.text" />
				</h2>
			</div>
		);
}

export default Loading;