import GamePassApi from "../api/GamePassApi";

export default class GamePassService {
    static validateUser = (ticket: string) => {
        return GamePassApi.get(
            `/v1/applications/global/webservices/msgamepass/api/user/validation`,
            {
                headers: {
                    'Ubi-AppId': process.env.REACT_APP_APPLICATION_ID as string,
                    'Authorization': `ubi_v1 t=${ticket}`
                }
            }
        );
    };

    static activateUser = (ticket: string) => {
        return GamePassApi.get(
            `/v1/applications/global/webservices/msgamepass/api/user/delivery`,
            {
                headers: {
                    'Ubi-AppId': process.env.REACT_APP_APPLICATION_ID as string,
                    'Authorization': `ubi_v1 t=${ticket}`
                }
            }
        );
    };

    static checkOwnership = (ticket: string, productId: number) => {
        return GamePassApi.get(
            `/v1/applications/global/webservices/msgamepass/api/user/ownership/${productId}`,
            {
                headers: {
                    'Ubi-AppId': process.env.REACT_APP_APPLICATION_ID as string,
                    'Authorization': `ubi_v1 t=${ticket}`
                }
            }
        );
    };
}