import ubilogo from "../resources/images/logos/ubi-logo.svg";
import ubiConnect from "../resources/images/logos/ubi-connect-logo.svg";
import ubiStore from "../resources/images/logos/ubi-store-logo.svg";
import xboxLogo from "../resources/images/logos/xbox-logo.svg";
import {FormattedMessage} from "react-intl";
import {languageLabelsArray} from "../resources/locales";
import {useContext} from "react";
import {LocaleContext} from "../context/LocaleContext";

const Footer = () => {
    const localeContext = useContext(LocaleContext);

    return (
        <footer>
            <div className="footer-container">

                <select name="language" id="language" value={localeContext.locale} onChange={(e) => localeContext.changeLocale(e.target.value)}>
                    {
                        languageLabelsArray.map((lang) => {
                            return <option key={lang.value} value={lang.value}>{lang.label}</option>;
                        })
                    }
                </select>

                <div className="brand-logos">
                    <img src={ubilogo} alt="Ubisoft Logo" />
                    <img src={ubiConnect} alt="Ubisoft Connect Logo" />
                    <img src={ubiStore} alt="Ubisoft Store Logo" />
                    <img src={xboxLogo} alt="PC Gamepass Logo" style={{width:"5rem"}} />
                </div>

                <nav className="footer">
                    <a href="https://www.ubisoft.com/help" target="_blank" rel="noreferrer"><FormattedMessage id="footer.link.support" /></a>
                    <a href="https://legal.ubi.com/termsofuse" target="_blank" rel="noreferrer"><FormattedMessage id="footer.link.tos" /></a>
                    <a href="https://legal.ubi.com/LegalInfoUbisoftEMEA" target="_blank" rel="noreferrer"><FormattedMessage id="footer.link.legal" /></a>
                    <a href="https://legal.ubi.com/privacypolicy" target="_blank" rel="noreferrer"><FormattedMessage id="footer.link.privacy" /></a>
                </nav>

                <p className="copyright"><FormattedMessage id="footer.rights.notice" /></p>
            </div>
        </footer>
    );
}

export default Footer;