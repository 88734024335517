import completeIcon from "../resources/images/FlowIcons/complete-icon.svg";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import {FormattedMessage} from "react-intl";
import React from "react";

interface Props {
    xboxProfile: UbisoftProfile;
    ubisoftProfile: UbisoftProfile;
}

const Complete = (props: Props) => {
	return (
		<>
			<img className="icon" src={completeIcon} alt="Complete" />
			<h2>
				<FormattedMessage id="page.success.title.web" />
			</h2>
			<p>
				<FormattedMessage
					id="page.success.description.web"
					values={{
						userName: <b>{props.ubisoftProfile.nameOnPlatform}</b>,
						xboxUserName: <b>{props.xboxProfile.nameOnPlatform}</b>,
					}}
				/>
			</p>

			<span>
				<FormattedMessage
					id="page.success.help.web"
					values={{
						visitHelpLink: (
							<a
								className="link"
								href="https://www.ubisoft.com/help/article/000100613"
								target="_blank"
								rel="noreferrer"
							>
								<FormattedMessage id="page.success.help.link.web" />
							</a>
						),
					}}
				/>
			</span>
		</>
	);
};

export default Complete;