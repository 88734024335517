import ConnectApi from "../api/ConnectApi";

export default class ConnectService {
    static refreshSso = (ticket: string) => {
        return ConnectApi.post(
            `/api/sso/v1/refresh`,
            {},
            {
                headers: {
                    'Ubi-AppId': process.env.REACT_APP_APPLICATION_ID as string,
                    'Authorization': `ubi_v1 t=${ticket}`
                }
            }
        );
    };
}