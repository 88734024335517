import React, {useCallback, useEffect, useState} from "react";
import {Locale} from "../data/types/Locale";
import locales from "../resources/locales";
import {useNavigate} from "react-router-dom";

interface Props {
    locale: Locale;
    children?: JSX.Element;
}

export interface LocaleContextData {
    locale: Locale;
    changeLocale: (changedLocale: string) => void;
}

export const LocaleContext = React.createContext<LocaleContextData>({
    locale: (process.env.REACT_APP_DEFAULT_LOCALE || '') as Locale,
    changeLocale: () => {}
});

const LocaleProvider = (props: Props) => {
    const [locale, setLocale] = useState<Locale>(props.locale);
    const navigate = useNavigate();

    const changeLocale = useCallback((changedLocale: string) => {
        if (changedLocale in locales) {
            let newLocale = changedLocale as Locale;

            navigate(window.location.pathname.replace(locale, newLocale));

            setLocale(newLocale);
        } else {
            console.warn(`Invalid locale: ${locale}`);
        }
    }, [locale, navigate]);

    useEffect(() => {
        changeLocale(props.locale);
    }, [props.locale, changeLocale]);

    return (
        <LocaleContext.Provider value={{
            locale: locale,
            changeLocale: changeLocale
        }}>
            {props.children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;