import completeIcon from "../resources/images/FlowIcons/complete-icon.svg";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import {FormattedMessage} from "react-intl";
import {Game, gameMappings} from "../data/types/Game";
import React from "react";

interface Props {
    xboxProfile?: UbisoftProfile;
    ubisoftProfile?: UbisoftProfile;
    game: Game;
}

const UpcComplete = (props: Props) => {
    const downloadGame = () => {
        if (props.game) {
            window.open(`uplay://open/game/${gameMappings[props.game]}?subsection=overview`, "_self");

            setTimeout(() => {
                if (props.game) {
                    window.open(`uplay://launch/${gameMappings[props.game]}`, "_self");
                }
            }, 1500);
        }
    };

    return (
        <>
            <img className="icon" src={completeIcon} alt="Complete" />
            <h2><FormattedMessage id="page.success.title" /></h2>
            <p>
                <FormattedMessage
                    id={`page.success.description`}
                    values={{
                        gamename: <FormattedMessage id={`game.${props.game}`} />,
                        userName: props.ubisoftProfile?.nameOnPlatform ?? '',
                        xboxUserName: props.xboxProfile?.nameOnPlatform ?? ''
                    }}
                />
            </p>

            <button className="btn" onClick={downloadGame}><FormattedMessage id="button.download_game" /></button>
        </>
    );
};

export default UpcComplete;