const DownloadIcon = () => {
    return (
        <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83333 0.8125C5.83333 0.363769 5.46024 0 5 0C4.53976 0 4.16667 0.363769 4.16667 0.8125L4.16667 6.34046L2.55253 4.66093C2.23784 4.33349 1.71048 4.31678 1.37464 4.6236C1.03881 4.93043 1.02166 5.4446 1.33636 5.77204L4.39191 8.95139C4.54946 9.11532 4.76959 9.20833 5 9.20833C5.23041 9.20833 5.45054 9.11532 5.60809 8.95139L8.66364 5.77204C8.97834 5.4446 8.96119 4.93043 8.62536 4.6236C8.28952 4.31678 7.76216 4.33349 7.44747 4.66093L5.83333 6.34046L5.83333 0.8125Z" fill="#F2F2F2"/>
        <path d="M0.833333 11.375C0.373096 11.375 0 11.7388 0 12.1875C0 12.6362 0.373096 13 0.833333 13H9.16667C9.6269 13 10 12.6362 10 12.1875C10 11.7388 9.6269 11.375 9.16667 11.375H0.833333Z" fill="#F2F2F2"/>
        </svg>
        
    )
}

export default DownloadIcon