import en from "./locale-en-US.json";
import fr from "./locale-fr-FR.json";
import it from "./locale-it-IT.json";
import de from "./locale-de-DE.json";
import eses from "./locale-es-ES.json";
import esmx from "./locale-es-MX.json";
import ru from "./locale-ru-RU.json";
import pl from "./locale-pl-PL.json";
import ptbr from "./locale-pt-BR.json";
import ja from "./locale-ja-JP.json";
import ko from "./locale-ko-KR.json";
import zhtw from "./locale-zh-TW.json";
import zhcn from "./locale-zh-CN.json";
import nl from "./locale-nl-NL.json";
import cs from "./locale-cs-CZ.json";
import da from "./locale-da-DK.json";
import fi from "./locale-fi-FI.json";
import hu from "./locale-hu-HU.json";
import nb from "./locale-nb-NO.json";
import ptpt from "./locale-pt-PT.json";
import sv from "./locale-sv-SE.json";
import tr from "./locale-tr-TR.json";
import th from "./locale-th-TH.json";
import vi from "./locale-vi-VN.json";
import id from "./locale-id-ID.json";

const locales = {
	"en-us": en,
	"fr-fr": fr,
	"it-it": it,
	"de-de": de,
	"es-es": eses,
	"es-mx": esmx,
	"ru-ru": ru,
	"pl-pl": pl,
	"pt-br": ptbr,
	"ja-jp": ja,
	"ko-kr": ko,
	"zh-tw": zhtw,
	"zh-cn": zhcn,
	"nl-nl": nl,
	"cs-cz": cs,
	"da-dk": da,
	"fi-fi": fi,
	"hu-hu": hu,
	"nb-no": nb,
	"pt-pt": ptpt,
	"sv-se": sv,
	"tr-tr": tr,
	"th-th": th,
	"vi-vn": vi,
	"id-id": id,
};

export const languageLabelsArray = [
	{
		value: "en-us",
		label: "English",
	},
	{
		value: "fr-fr",
		label: "Français",
	},
	{
		value: "it-it",
		label: "Italiano",
	},
	{
		value: "de-de",
		label: "Deutsch",
	},
	{
		value: "es-es",
		label: "Español",
	},
	{
		value: "es-mx",
		label: "Español (México)",
	},
	{
		value: "ru-ru",
		label: "Русский",
	},
	{
		value: "pl-pl",
		label: "Polski",
	},
	{
		value: "pt-br",
		label: "Português (Brasil)",
	},
	{
		value: "ja-jp",
		label: "日本語",
	},
	{
		value: "ko-kr",
		label: "한국어",
	},
	{
		value: "zh-tw",
		label: "繁體中文",
	},
	{
		value: "zh-cn",
		label: "英语",
	},
	{
		value: "nl-nl",
		label: "Nederlands",
	},
	{
		value: "cs-cz",
		label: "Čeština",
	},
	{
		value: "da-dk",
		label: "Dansk",
	},
	{
		value: "fi-fi",
		label: "Suomi",
	},
	{
		value: "hu-hu",
		label: "Magyar",
	},
	{
		value: "nb-no",
		label: "Norsk",
	},
	{
		value: "pt-pt",
		label: "Português",
	},
	{
		value: "sv-se",
		label: "Svenska",
	},
	{
		value: "tr-tr",
		label: "Türkçe",
	},
	{
		value: "th-th",
		label: "ไทย",
	},
	{
		value: "vi-vn",
		label: "Tiếng Việt",
	},
	{
		value: "id-id",
		label: "Bahasa Indonesia",
	},
] as Record<string, string>[];

export const languageMappings = {
	en: "en-us",
	"en-AU": "en-us",
	"en-CA": "en-us",
	"en-IN": "en-us",
	"en-NZ": "en-us",
	"en-ZA": "en-us",
	"en-UK": "en-us",
	fr: "fr-fr",
	it: "it-it",
	de: "de-de",
	es: "es-es",
	ru: "ru-ru",
	pl: "pl-pl",
	ja: "ja-jp",
	ko: "ko-kr",
	nl: "nl-nl",
	cs: "cs-cz",
	da: "da-dk",
	fi: "fi-fi",
	hu: "hu-hu",
	nb: "nb-no",
	pt: "pt-pt",
	sv: "sv-se",
	tr: "tr-tr",
	th: "th-th",
	vi: "vi-vn",
	id: "id-id",
} as Record<string, string>;

export default locales;