import React, {useEffect, useState} from "react";
import {Platform} from "../data/types/Platform";
import {Game} from "../data/types/Game";

interface Props {
    platform: Platform | null;
    game?: string | null;
    children?: JSX.Element;
}

export interface PlatformContextData {
    platform: Platform;
    game: Game | null;
}

export const PlatformContext = React.createContext<PlatformContextData>({
    platform: null,
    game: null
});

const PlatformProvider = (props: Props) => {
    const [platform, setPlatform] = useState<Platform>(null);
    const [game, setGame] = useState<Game>(null);

    useEffect(() => {
        setPlatform(props.platform);

        if (props.game) {
            // @ts-ignore
            setGame(props.game);
        }
    }, [props.platform, props.game, game]);

    return (
        <PlatformContext.Provider value={{
            platform: platform,
            game: game
        }}>
            {props.children}
        </PlatformContext.Provider>
    );
};

export default PlatformProvider;