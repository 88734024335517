import React, {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "../context/UserContext";
import {LocaleContext} from "../context/LocaleContext";
import Connect from "./auth/Connect";
import Accounts from "../containers/Accounts";
import Loading from "../components/Loading";
import {Environment} from "../data/types/Environment";
import GamePassService from "../data/services/GamePassService";
import MissingGamePass from "../components/MissingGamePass";
import {AxiosError} from "axios";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import {profilePlatformTypes} from "../data/types/UserData";

const ERROR_CODE_MISSING_XBOX_PROFILE = 400002;
const ERROR_CODE_XBOX_REAUTHENTICATE = 400005;

const WebFlow = () => {
    const userContext = useContext(UserContext);
    const localeContext = useContext(LocaleContext);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [hasValidGamePass, setHasValidGamePass] = useState<boolean>(false);
    const [xboxProfile, setXboxProfile] = useState<UbisoftProfile>();

    useEffect(() => {
        const xboxProfile = userContext.user.profiles.find((profile: UbisoftProfile) => profile.platformType === profilePlatformTypes.xbox);

        if (xboxProfile) {
            setXboxProfile(xboxProfile);
        }
    }, [userContext.user.profiles]);

    const validateUser = useCallback(() => {
        if (userContext.user.ticketData) {
            GamePassService.validateUser(userContext.user.ticketData.ticket).then(() => {
                setHasValidGamePass(true);
                setLoaded(true);
            }).catch((error: AxiosError) => {
                if (error.response?.data.errorCode === ERROR_CODE_MISSING_XBOX_PROFILE
                || error.response?.data.errorCode === ERROR_CODE_XBOX_REAUTHENTICATE) {
                    userContext.logout(() => {
                        setLoaded(true);
                    });
                } else {
                    setLoaded(true);
                }
            });
        }
    }, [userContext.user.ticketData]);

    useEffect(() => {
        if (userContext.authVerified) {
            if (userContext.user.isAuthenticated) {
                validateUser();
            } else {
                setLoaded(true);
            }
        }
    }, [userContext.authVerified, userContext.user.isAuthenticated, validateUser]);

    if (loaded && userContext.authVerified && !userContext.user.isAuthenticated) {
        return (
            <Connect
                applicationId={process.env.REACT_APP_APPLICATION_ID!}
                genomeId={process.env.REACT_APP_GENOME_ID!}
                environment={process.env.REACT_APP_ENVIRONMENT! as Environment}
                nextUrl={`${process.env.REACT_APP_BASE_PATH}/connect-next-url`}
                locale={localeContext.locale}
            />
        );
    } else if (loaded && userContext.authVerified && hasValidGamePass) {
        return (
            <Accounts />
        );
    } else if (loaded && userContext.authVerified && !hasValidGamePass) {
        return (
            <MissingGamePass xboxProfile={xboxProfile} />
        );
    } else {
        return (
            <Loading />
        );
    }
}

export default WebFlow;