import React from "react";
import update from 'immutability-helper';
import {profilePlatformTypes, TicketData, UserData} from "../data/types/UserData";
import {UbisoftProfile} from "../data/types/UbisoftProfile";

interface UserContextData {
    user: UserData;
    login: (ticketData: TicketData, profiles: UbisoftProfile[], callback?: () => void) => void;
    logout: (callback?: () => void) => void;
    handeNoLoginData: (callback?: () => void) => void;
    setAuthVerified: (authVerified: boolean) => void;
    authVerified: boolean;
}

const guestUser: UserData = {
    isAuthenticated: false,
    profiles: [],
};

export const UserContext = React.createContext<UserContextData>({
    user: guestUser,
    login: () => {},
    logout: () => {},
    handeNoLoginData: () => {},
    setAuthVerified: () => {},
    authVerified: false,
});

export default class UserProvider extends React.Component<object, UserContextData> {
    login = (ticketData: TicketData, profiles: UbisoftProfile[], callback = () => {}) => {
        const uplayProfile = profiles.find(profile => profile.platformType === profilePlatformTypes.uplay);
        const username: string = uplayProfile ? uplayProfile.nameOnPlatform : "";

        this.setState(prevState => ({
            user: update(prevState.user, {
                isAuthenticated: {$set: true},
                ticketData: {$set: ticketData},
                username: {$set: username},
                profiles: {$set: profiles},
            }),
        }), () => {
            callback();
            this.setAuthVerified(true);
        });
    };

    handeNoLoginData = () => {
        this.setAuthVerified(true);
    }

    logout = (callback = () => {}) => {
        this.setState({user: guestUser}, () => {
            callback();
            this.setAuthVerified(true);
        });
    };

    setAuthVerified = (authVerified: boolean) => {
        this.setState({authVerified: authVerified});
    };

    state: UserContextData = {
        user: guestUser,
        login: this.login,
        logout: this.logout,
        handeNoLoginData: this.handeNoLoginData,
        setAuthVerified: this.setAuthVerified,
        authVerified: false,
    };

    render(): JSX.Element {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
