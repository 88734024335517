import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import ConnectCallback from "./containers/auth/ConnectCallback";
import './resources/sass/main.scss';
import locales, {languageMappings} from './resources/locales';
import Main from "./containers/Main";
import {LocaleContext} from "./context/LocaleContext";
import ReverseLink from "./containers/auth/ReverseLink";
import {Environment} from "./data/types/Environment";
import ReverseCallback from "./containers/auth/ReverseCallback";
import WebFlow from "./containers/WebFlow";
import UpcFlow from "./containers/UpcFlow";
import UpcMain from './containers/UpcMain';
import {
    GAME_AC_ORIGINS,
    GAME_FAR_CRY5, GAME_FENIX_RISING,
    GAME_FOR_HONOR, GAME_GR_WILDLANDS,
    GAME_R6_EXTRACTION,
    GAME_R6_SIEGE,
    GAME_WATCH_DOGS2
} from "./data/types/Game";
import classNames from "classnames";
import * as queryString from "querystring";
import UpcErrorRetry from "./components/UpcErrorRetry";
import MainGeneric from "./containers/MainGeneric";

const QUERY_KEY_UPC_LOCALE = 'upc-locale';
const QUERY_KEY_GAME = 'game';

function App() {
    const parsedQuery = queryString.parse(window.location.search.substr(1));

    const getBrowserLanguage = (): string => {
        if (navigator.languages) {
            return navigator.languages[0];
        } else {
            return navigator.language;
        }
    }

    const getUserLanguage = (): string => {
        let browserLanguage = getBrowserLanguage();
        let userLanguage: string | undefined = process.env.REACT_APP_DEFAULT_LOCALE;

        if (typeof parsedQuery[QUERY_KEY_UPC_LOCALE] === 'string') {
            userLanguage = parsedQuery[QUERY_KEY_UPC_LOCALE] as string;
        } else if (browserLanguage.toLowerCase() in locales) {
            userLanguage = browserLanguage.toLowerCase();
        } else if (browserLanguage in languageMappings) {
            userLanguage = languageMappings[browserLanguage].toLowerCase();
        }

        if (userLanguage) {
            return userLanguage.toLowerCase();
        } else {
            return "en-us";
        }
    }

    return (
        <div className={classNames({
            App: true,
            upc: window.location.pathname.indexOf('/upc') !== -1,
            r6s: parsedQuery[QUERY_KEY_GAME] === GAME_R6_SIEGE,
            r6e: parsedQuery[QUERY_KEY_GAME] === GAME_R6_EXTRACTION,
            fh: parsedQuery[QUERY_KEY_GAME] === GAME_FOR_HONOR,
            acor: parsedQuery[QUERY_KEY_GAME] === GAME_AC_ORIGINS,
            fc5: parsedQuery[QUERY_KEY_GAME] === GAME_FAR_CRY5,
            wd2: parsedQuery[QUERY_KEY_GAME] === GAME_WATCH_DOGS2,
            grw: parsedQuery[QUERY_KEY_GAME] === GAME_GR_WILDLANDS,
            ifr: parsedQuery[QUERY_KEY_GAME] === GAME_FENIX_RISING,
        })}>
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE_NAME}>
                <Routes>
                    <Route
                        path="connect-next-url"
                        element={<ConnectCallback />}
                    />

                    <Route
                        path=":locale/download"
                        element={<MainGeneric />}
                    />

                    <Route
                        path={`:locale`}
                        element={<Main />}
                    >
                        <Route
                            index
                            element={<WebFlow />}
                        />
                    </Route>

                    <Route
                        path={`upc/:locale`}
                        element={<UpcMain />}
                    >
                        <Route
                            index
                            element={<UpcFlow />}
                        />

                        <Route
                            path={`reverse`}
                            element={<>
                                <LocaleContext.Consumer>
                                    {({locale}) => (
                                        <ReverseLink
                                            applicationId={process.env.REACT_APP_APPLICATION_ID!}
                                            genomeId={process.env.REACT_APP_REVERSE_GENOME_ID!}
                                            environment={process.env.REACT_APP_ENVIRONMENT! as Environment}
                                            nextUrl={`${process.env.REACT_APP_BASE_PATH}/upc/${locale}/reverse-next-url${window.location.search}`}
                                            locale={locale}
                                        />
                                    )}
                                </LocaleContext.Consumer>
                            </>}
                        />

                        <Route
                            path={`error`}
                            element={<UpcErrorRetry />}
                        />

                        <Route
                            path={`reverse-next-url`}
                            element={<ReverseCallback />}
                        />
                    </Route>

                    <Route
                        path={`download`}
                        element={<>
                            <Navigate to={`/${getUserLanguage()}${window.location.search}/download`} />
                        </>}
                    />

                    <Route
                        path={`upc`}
                        element={<>
                            <Navigate to={`/upc/${getUserLanguage()}${window.location.search}`} />
                        </>}
                    />

                    <Route
                        path={`*`}
                        element={<Navigate to={`/${getUserLanguage()}${window.location.search}`} />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
