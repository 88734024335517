import errorIcon from "../resources/images/FlowIcons/error-icon.svg";

const ErrorCountry = () => {
    return (
        <div className="error">
            <img className="icon" src={errorIcon} alt="Error" />
            <h2>This game is not available in your country.</h2>
        </div>
    );
};

export default ErrorCountry;