import {Navigate, Outlet, useParams, useSearchParams} from "react-router-dom";
import {Locale} from "../data/types/Locale";
import {IntlProvider} from "react-intl";
import LocaleProvider from "../context/LocaleContext";
import PlatformProvider from "../context/PlatformContext";
import UserProvider from "../context/UserContext";
import locales from "../resources/locales";
import UpcFooter from "../components/UpcFooter";
import UpcHeader from "../components/UpcHeader";
import {PLATFORM_UPC} from "../data/types/Platform";
import {useEffect} from "react";

const UpcMain = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams) {
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    const getTranslations = (locale: Locale): Record<string, string> => {
        return Object.assign({}, locales["en-us"], locales[locale]);
    }

    let locale: Locale = params.locale as Locale || searchParams.get('upc-locale') as Locale || null;
    let translations = getTranslations(locale);
    let urlPathname = window.location.pathname.replace(locale, locale.toLowerCase());

    if (Object.keys(locales).indexOf(locale) < 0) {
        return <Navigate to={`/upc${window.location.search}`} />
    }

    if (locale.toLowerCase() !== locale) {
        return <Navigate to={`${urlPathname}${window.location.search}`} />
    }

    return (
			<PlatformProvider platform={PLATFORM_UPC} game={searchParams.get("game")}>
				<IntlProvider
					locale={locale}
					defaultLocale={process.env.REACT_APP_DEFAULT_LOCALE}
					messages={translations}
				>
					<LocaleProvider locale={locale}>
						<UserProvider>
							<div className="container upc">
								<UpcHeader />
								<section className="flow-container">
									<Outlet />
								</section>
							</div>

							<UpcFooter />
						</UserProvider>
					</LocaleProvider>
				</IntlProvider>
			</PlatformProvider>
		);
}

export default UpcMain;