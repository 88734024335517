import errorIcon from "../resources/images/FlowIcons/error-icon.svg";
import {FormattedMessage} from "react-intl";

const Error = () => {
    return (
			<div className="error">
				<img className="icon" src={errorIcon} alt="Error" />
				<h2>
					<FormattedMessage id="page.error.generic.web" />
				</h2>
				<p>
					<FormattedMessage id="page.error.generic.description.web" />
				</p>
			</div>
		);
};

export default Error;