import React from "react";
import {Navigate, useParams} from "react-router-dom";
import {Locale} from "../data/types/Locale";
import {IntlProvider} from "react-intl";
import LocaleProvider from "../context/LocaleContext";
import PlatformProvider from "../context/PlatformContext";
import locales from "../resources/locales";
import {PLATFORM_WEB} from "../data/types/Platform";
import Download from "../components/Download";

const MainGeneric = () => {
    const params = useParams();

    const getTranslations = (locale: Locale): Record<string, string> => {
        return Object.assign({}, locales["en-us"], locales[locale]);
    }

    let locale: Locale = params.locale as Locale || null;
    let translations = getTranslations(locale);
    let urlPathname = window.location.pathname.replace(locale, locale.toLowerCase());

    if (Object.keys(locales).indexOf(locale) < 0) {
        return <Navigate to={`/`} />
    }

    if (locale.toLowerCase() !== locale) {
        return <Navigate to={`${urlPathname}`} />
    }

    return (
        <PlatformProvider platform={PLATFORM_WEB}>
            <IntlProvider locale={locale}
                          defaultLocale={process.env.REACT_APP_DEFAULT_LOCALE}
                          messages={translations}>
                <LocaleProvider locale={locale}>
                    <Download />
                </LocaleProvider>
            </IntlProvider>
        </PlatformProvider>
    );
}

export default MainGeneric;