import React, {Component} from "react";
import {loadScript} from "../../utils/Browser";
import {Environment} from "../../data/types/Environment";
import {UserContext} from "../../context/UserContext";
import {UbisoftProfile} from "../../data/types/UbisoftProfile";
import {IDisposable, Observable } from "rx";
import { isMobile } from "react-device-detect";
import {FormattedMessage} from "react-intl";
import {Platform, PLATFORM_WEB} from "../../data/types/Platform";
import {Locale} from "../../data/types/Locale";

interface State {
    loading: boolean;
    isMobile: boolean;
}

interface Props {
    environment: Environment;
    applicationId: string;
    genomeId: string;
    locale: Locale;
    platform: Platform;
}

interface WebAuthSDK {
    readonly init: (options: object) => void;
    readonly getTicket: () => Observable<WebAuthTicketResponse>;
    readonly getProfiles: (ticket: string, sessionId: string, userId: string) => Observable<WebAuthProfilesResponse>;
    readonly logout: (ticket: string, sessionId: string) => Observable<IDisposable>;
}

export interface WebAuthTicketResponse {
    readonly status: 'ok' | 'error';
    readonly payload: {
        readonly userId: string;
        readonly expiration: string;
        readonly sessionId: string;
        readonly ticket: string;
    };
}

export interface WebAuthProfilesResponse {
    readonly status: 'ok' | 'error';
    readonly payload: {
        profiles: UbisoftProfile[];
    };
}

export default class WebAuth extends Component<Props, State> {
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;
    SDK!: WebAuthSDK;

    refreshTimer: ReturnType<typeof setInterval> | null = null;

    private resourceUrls = {
        uat: "https://ubistatic2-a.akamaihd.net/uplay-connect/v3/uat/default/sdk/connectSdkPublic.js",
        prod: "https://ubistatic2-a.akamaihd.net/uplay-connect/v3/prod/default/sdk/connectSdkPublic.js",
    };

    private avatarBasePaths = {
        uat: "https://uat-ubisoft-avatars.akamaized.net",
        prod: "https://ubisoft-avatars.akamaized.net",
    };

    state: State = {
        loading: true,
        isMobile: false
    };

    componentDidMount(): void {
        loadScript(this.resourceUrls[this.props.environment]).then(() => this.init());
        this.setState({isMobile: isMobile})
    }

    componentWillUnmount() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
    }

    init = () => {
        const {applicationId, genomeId, locale} = this.props;

        (window as any).Connect.init({
            env: this.props.environment.toUpperCase(),
            appId: applicationId,
            genomeId: genomeId,
            lang: locale,
            nextUrl: encodeURIComponent(`${process.env.REACT_APP_BASE_PATH}/connect-next-url`),
            thirdPartyCookiesSupport: false
        });

        (window as any).Connect.sdk.subscribe((sdk: WebAuthSDK) => {
            this.SDK = sdk;

            this.SDK.getTicket().subscribe((response: WebAuthTicketResponse) => {
                this.handleConnectResponse(response);
            });
        });

        this.refreshTimer = setInterval(this.refreshTicket, 3600 * 1000);

        (window as any).logoutUser = (returnPath: string = '', cancelUbisoftLogout: boolean = false) => this.handleLogout(returnPath, cancelUbisoftLogout);
    };

    refreshTicket = (): void => {
        this.SDK.getTicket().subscribe((response: WebAuthTicketResponse) => {
            this.handleConnectResponse(response);
        });
    };

    render(): JSX.Element | null {
        return this.context.user.isAuthenticated && this.props.platform === PLATFORM_WEB
        ? <div className={ isMobile ? "auth auth-mobile" : "auth hover"} onClick={() => this.handleLogout()}>
            <span className="user">{this.context.user.username}</span>
            <span className="log-out"><FormattedMessage id="button.logout" /></span>
            <img src={this.getUserAvatar()} alt="Avatar" />
        </div> : null;
    }

    getUserAvatar = () => {
        const uplayProfile = this.context.user.profiles.find((profile) => profile.platformType === 'uplay');

        return `${this.avatarBasePaths[this.props.environment]}/${uplayProfile?.profileId}/default_146_146.png?appId=${this.props.applicationId}`;
    }

    handleLogout = (returnPath: string = '', cancelUbisoftLogout: boolean = false) => {
        window.open(`${process.env.REACT_APP_CONNECT_BASE_PATH}/xbox-logout?appId=${process.env.REACT_APP_APPLICATION_ID}${cancelUbisoftLogout ? '&cancelUbiLogout=true' : ''}&nextUrl=${encodeURIComponent(process.env.REACT_APP_BASE_PATH + returnPath)}`, "_self");
    };

    handleNoLoginData = () => {
        this.context.handeNoLoginData(() => {
            this.setState({loading: false})
        });
    };

    handleConnectResponse = (response: WebAuthTicketResponse) => {
        if (response.status === 'ok') {
            this.handleSuccessfulLogin(response);
        } else {
            this.handleNoLoginData();
        }
    };

    private handleSuccessfulLogin(loginResponse: WebAuthTicketResponse) {
        this.SDK.getProfiles(
            loginResponse.payload.ticket,
            loginResponse.payload.sessionId,
            loginResponse.payload.userId
        ).subscribe((response: WebAuthProfilesResponse) => {
            if (response.status === "ok") {
                this.context.login(loginResponse.payload, response.payload.profiles, () => {
                    this.setState({loading: false})
                });
            } else {
                window.location.reload();
            }
        });
    }
}