import React, {useCallback, useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import useNavigateParams from "../../hooks/useNavigateParams";
import Loading from "../../components/Loading";

const WEB_AUTH_ERROR_QUERY_PARAM = 'webauthloginerror';

const ReverseCallback = () => {
    const navigateParams = useNavigateParams();
    const [searchParams] = useSearchParams();
    const params = useParams();

    const getUpcQueryParams = (searchParams: URLSearchParams) => {
        return {
            game: searchParams.get('game') || '',
            'upc-locale': searchParams.get('upc-locale') || '',
        }
    };

    const triggerReverseLogin = useCallback(() => {
        const error = searchParams.get(WEB_AUTH_ERROR_QUERY_PARAM);

        if (error) {
            navigateParams(`/upc/${params.locale}/error`, {
                ...getUpcQueryParams(searchParams),
                'error': error
            });
        } else {
            navigateParams(`/upc/${params.locale}`, getUpcQueryParams(searchParams));
        }
    }, []);

    useEffect(() => {
        triggerReverseLogin();
    });

    return <Loading />;
}

export default ReverseCallback;