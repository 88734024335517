import {useContext} from "react";
import {PlatformContext} from "../context/PlatformContext";
import {FormattedMessage} from "react-intl";
import {gameMappings} from "../data/types/Game";

const UpcHeader = () => {
    const platformContext = useContext(PlatformContext);

    return (
        <header className="upc">
            {platformContext.game && gameMappings[platformContext.game] && <h1><FormattedMessage id="page.login.title" values={{
                gamename: <FormattedMessage id={`game.${platformContext.game}`} />
            }}/></h1>}
            <h2><FormattedMessage id="page.header.activate"/></h2>
        </header>
    );
}

export default UpcHeader;