import { useState } from "react";
import {UbisoftProfile} from "../data/types/UbisoftProfile";
import xboxIcon from "../resources/images/xbox-icon.svg";
import ubiIcon from "../resources/images/ubi-icon.svg";
import { FormattedMessage } from "react-intl";

interface Props {
    xboxProfile: UbisoftProfile;
    ubisoftProfile: UbisoftProfile;
    activateUser: () => void;
}

const AccountsComponent = (props: Props) => {
    const [checked, setChecked] = useState<boolean>(false);

    return (
			<>
				<h2>
					<FormattedMessage id="page.activation.title.web" />
				</h2>

				<p>
					<FormattedMessage id="page.activation.subtitle.web" />
				</p>

				<div className="flow-content accounts">
					<div className="profile-box">
						<img src={xboxIcon} alt="Xbox Profile" />
						<span className="profile-name">
							<FormattedMessage
								id="page.xbox_username"
								values={{ xboxUserName: props.xboxProfile.nameOnPlatform }}
							/>
						</span>
						<button
							className="link"
							onClick={() => (window as any).logoutUser()}
						>
							<FormattedMessage id="button.cta.change_account" />
						</button>
					</div>

					<div className="profile-box">
						<img src={ubiIcon} alt="Ubisoft Profile" />
						<span className="profile-name">
							{props.ubisoftProfile.nameOnPlatform}
						</span>
						<span className="link-container">
							<a
								className="link external"
								href="https://www.ubisoft.com/help/article/000100613"
								target="_blank"
								rel="noreferrer"
							>
								<FormattedMessage id="button.bad_account" />
							</a>
						</span>
					</div>

					<label>
						<input
							type="checkbox"
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<FormattedMessage id="page.activation.warning.web" />
					</label>
				</div>

				<button
					className="btn"
					onClick={props.activateUser}
					disabled={!checked}
				>
					<FormattedMessage id="page.activation.confirm.cta.web" />
				</button>
			</>
		);
}

export default AccountsComponent;