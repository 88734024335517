import React, {useCallback, useContext, useEffect, useState} from "react";
import {PlatformContext} from "../../context/PlatformContext";
import {useSearchParams} from "react-router-dom";
import {Environment} from "../../data/types/Environment";
import {PLATFORM_UPC} from "../../data/types/Platform";
import {UserContext} from "../../context/UserContext";
import ConnectService from "../../data/services/ConnectService";
import {AxiosError, AxiosResponse} from "axios";
import Error from "../../components/Error";
import Login from "../../components/Login";
import useNavigateParams from "../../hooks/useNavigateParams";

interface Props {
    environment: Environment;
    applicationId: string;
    genomeId: string;
    locale: string;
    nextUrl: string;
}

const ReverseLink = (props: Props) => {
    const userContext = useContext(UserContext);
    const platformContext = useContext(PlatformContext);

    const [hasError, setHasError] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigateParams = useNavigateParams();

    useEffect(() => {
        if (searchParams) {
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    const reverseLogin = useCallback(() => {
        const { applicationId, genomeId, locale, nextUrl } = props;

        if (userContext.user.ticketData?.ticket) {
            ConnectService.refreshSso(userContext.user.ticketData.ticket).then((response: AxiosResponse) => {
                if (response.data.ssoId) {
                    (window as any).location = `${process.env.REACT_APP_CONNECT_BASE_PATH}/xbox-reversed?appId=${applicationId}&genomeId=${genomeId}&lang=${locale}&ssoId=${encodeURIComponent(response.data.ssoId)}&nextUrl=${encodeURIComponent(nextUrl)}`;
                } else {
                    setHasError(true);
                }
            }).catch((error: AxiosError) => {
                setHasError(true);
            })
        }
    }, [props, userContext.user]);

    useEffect(() => {
			if (
				!userContext.authVerified ||
				!userContext.user.isAuthenticated ||
				platformContext.platform !== PLATFORM_UPC
			) {
				navigateParams(`/upc/${props.locale}`, {
					game: searchParams.get("game") || "",
					"upc-locale": searchParams.get("upc-locale") || "",
				});
			}
		}, [
			userContext.authVerified,
			userContext.user.isAuthenticated,
			platformContext.platform,
			props.locale,
			reverseLogin,
			navigateParams,
			searchParams,
		]);

    if (hasError) {
        return <Error />
    }

    return <Login loginAction={reverseLogin} />;
}

export default ReverseLink;

