import gamepassLogo from "../resources/images/logos/logo-pc-gamepass.svg";
import {FormattedMessage} from "react-intl";

const Header = () => {
    return (
			<header>
				<img src={gamepassLogo} alt="PC Gamepass" />
				<h1>
					<FormattedMessage id="page.login.title.web" />
				</h1>
			</header>
		);
}

export default Header;