import userErrorIcon from "../resources/images/FlowIcons/user-error-icon.svg";
import {FormattedMessage} from "react-intl";
import React from "react";
import {UbisoftProfile} from "../data/types/UbisoftProfile";

interface Props {
    retryFlow: () => void;
    xboxProfile?: UbisoftProfile;
}

const UpcMissingGamePass = (props: Props) => {
    const openGamePassRegistration = () => {
        window.open("https://www.xbox.com/xbox-game-pass/pc-game-pass?OCID=PCGamePass_UbiConnect_WebFlow", "_blank");
    };

    return (
        <>
            <img className="icon" src={userErrorIcon} alt="Error" />
            <h2><FormattedMessage id="page.error.not_subscribed.title" /></h2>
            <p>
                <FormattedMessage
                    id="page.error.not_subscribed.description"
                    values={{
                        xboxUserName: props.xboxProfile?.nameOnPlatform ?? ''
                    }}
                />
            </p>
            <button className="btn mb-2" onClick={openGamePassRegistration}><FormattedMessage id="button.join_now" /></button>
            <span>If you have subscribed to PC Game Pass or Xbox Game Pass Ultimate in the meanwhile,<button className="link" onClick={props.retryFlow}>retry here</button></span>
        </>
    );
}

export default UpcMissingGamePass;