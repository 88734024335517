import userErrorIcon from "../resources/images/FlowIcons/user-error-icon.svg";
import {FormattedMessage} from "react-intl";
import React from "react";
import {UbisoftProfile} from "../data/types/UbisoftProfile";

interface Props {
    xboxProfile?: UbisoftProfile;
}

const MissingGamePass = (props: Props) => {
    const openGamePassRegistration = () => {
        window.open("https://www.xbox.com/xbox-game-pass/pc-game-pass?OCID=PCGamePass_UbiConnect_WebFlow", "_blank");
    };

    return (
			<>
				<img className="icon" src={userErrorIcon} alt="Error" />
				<h2>
					<FormattedMessage id="page.error.not_subscribed.title.web" />
				</h2>
				<p>
					<FormattedMessage
						id="page.error.not_subscribed.description.web"
						values={{
							xboxUserName: <b>{props.xboxProfile?.nameOnPlatform}</b> ?? "",
						}}
					/>
				</p>
				<button className="btn mb-2" onClick={openGamePassRegistration}>
					<FormattedMessage id="button.cta.subscribe_gamepass" />
				</button>
				<span>
					<FormattedMessage
						id="page.subscribe.login.message"
						values={{
							loginLink: (
								<button
									className="link"
									onClick={() => (window as any).logoutUser()}
								>
									<FormattedMessage id="page.login.link" />
								</button>
							),
						}}
					/>
				</span>
			</>
		);
}

export default MissingGamePass;