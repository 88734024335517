import { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { UbisoftProfile } from "../data/types/UbisoftProfile";
import GamePassService from "../data/services/GamePassService";
import { UserContext } from "../context/UserContext";
import { profilePlatformTypes } from "../data/types/UserData";
import AccountsComponent from "../components/Accounts";
import Error from "../components/Error";
import GameAlreadyActivated from "../components/GameAlreadyActivated";
import UpcComplete from "../components/UpcComplete";
import { PlatformContext } from "../context/PlatformContext";
import { PLATFORM_UPC, PLATFORM_WEB } from "../data/types/Platform";
import Complete from "../components/Complete";
import UpcAccounts from "../components/UpcAccounts";
import UpcGameAlreadyActivated from "../components/UpcGameAlreadyActivated";
import TooManyAccountUnlinksError from "../components/TooManyAccountUnlinksError";
import ErrorRetry from "../components/ErrorRetry";

const Accounts = () => {
	const userContext = useContext(UserContext);
	const platformContext = useContext(PlatformContext);

	const [xboxProfile, setXboxProfile] = useState<UbisoftProfile | undefined>();
	const [ubisoftProfile, setUbisoftProfile] = useState<
		UbisoftProfile | undefined
	>();
	const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);
	const [hasAlreadyActivated, setHasAlreadyActivated] =
		useState<boolean>(false);
	const [hasTooManyUnlinks, setHasTooManyUnlinks] = useState<boolean>(false);

	useEffect(() => {
		const xboxProfile = userContext.user.profiles.find(
			(profile: UbisoftProfile) =>
				profile.platformType === profilePlatformTypes.xbox
		);
		const ubisoftProfile = userContext.user.profiles.find(
			(profile: UbisoftProfile) =>
				profile.platformType === profilePlatformTypes.uplay
		);

		if (xboxProfile && ubisoftProfile) {
			setXboxProfile(xboxProfile);
			setUbisoftProfile(ubisoftProfile);
		} else {
			setHasError(true);
		}
	}, [userContext.user.profiles]);

	const activateUser = () => {
		if (userContext.user.ticketData) {
			GamePassService.activateUser(userContext.user.ticketData.ticket)
				.then((result) => {
					setIsSuccessful(true);
				})
				.catch((error: AxiosError) => {
					if (error.response?.data.errorCode === 409001) {
						setHasAlreadyActivated(true);
					} else if (error.response?.data.errorCode === 409002) {
						setHasTooManyUnlinks(true);
					} else {
						setHasError(true);
					}
				});
		}
	};

	if (hasError) {
		return <ErrorRetry />;
	} else if (
		hasAlreadyActivated &&
		platformContext.platform === PLATFORM_WEB &&
		xboxProfile &&
		ubisoftProfile
	) {
		return (
			<GameAlreadyActivated
				xboxProfile={xboxProfile}
				ubisoftProfile={ubisoftProfile}
			/>
		);
	} else if (hasAlreadyActivated && platformContext.platform === PLATFORM_UPC) {
		return <UpcGameAlreadyActivated />;
	} else if (hasTooManyUnlinks) {
		return <TooManyAccountUnlinksError />;
	} else if (
		xboxProfile &&
		ubisoftProfile &&
		!isSuccessful &&
		platformContext.platform === PLATFORM_WEB
	) {
		return (
			<AccountsComponent
				xboxProfile={xboxProfile}
				ubisoftProfile={ubisoftProfile}
				activateUser={activateUser}
			/>
		);
	} else if (
		xboxProfile &&
		ubisoftProfile &&
		!isSuccessful &&
		platformContext.platform === PLATFORM_UPC
	) {
		return (
			<UpcAccounts
				xboxProfile={xboxProfile}
				ubisoftProfile={ubisoftProfile}
				activateUser={activateUser}
			/>
		);
	} else if (
		xboxProfile &&
		ubisoftProfile &&
		isSuccessful &&
		platformContext.platform === PLATFORM_WEB
	) {
		return (
			<Complete xboxProfile={xboxProfile} ubisoftProfile={ubisoftProfile} />
		);
	} else if (
		xboxProfile &&
		ubisoftProfile &&
		isSuccessful &&
		platformContext.platform === PLATFORM_UPC
	) {
		return (
			<UpcComplete
				xboxProfile={xboxProfile}
				ubisoftProfile={ubisoftProfile}
				game={platformContext.game}
			/>
		);
	} else {
		return <Error />;
	}
};

export default Accounts;
