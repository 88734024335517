import errorIcon from "../resources/images/FlowIcons/error-icon.svg";
import { FormattedMessage } from "react-intl";

const ErrorRetry = () => {
	const openFAQ = () => {
		window.open("https://www.ubisoft.com/help/article/000101377", "_blank");
	};

	const openSupportPage = () => {
		window.open("https://www.ubisoft.com/help", "_blank");
	};

	return (
		<div className="error-retry">
			<img className="icon" src={errorIcon} alt="Error" />
			<h2>
				<FormattedMessage id="page.error.edge.web" />
			</h2>
			<p>
				<FormattedMessage id="page.error.edge.description.web" />
			</p>

			<div className="twoButtons">
				<button className="btn" onClick={openFAQ}>
					<FormattedMessage id="button.cta.visit_help" />
				</button>
				<button className="btn" onClick={openSupportPage}>
					<FormattedMessage id="button.cta.contact_support" />
				</button>
			</div>

			<span>
				<FormattedMessage id="page.error.message.retry.web" />
				<button className="link" onClick={() => (window as any).logoutUser()}>
					<FormattedMessage id="button.login" />
				</button>
			</span>
		</div>
	);
};

export default ErrorRetry;
