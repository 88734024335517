import {UbisoftProfile} from "./UbisoftProfile";

export const profilePlatformTypes = {
    uplay: "uplay",
    xbox: "xbl"
};

export type TicketData = {
    expiration: string;
    sessionId: string;
    ticket: string;
    userId: string;
}

export type UserData = {
    isAuthenticated: boolean;
    ticketData?: TicketData;
    username?: string;
    profiles: UbisoftProfile[],
}