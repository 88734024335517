import errorIcon from "../resources/images/FlowIcons/error-icon.svg";
import {FormattedMessage} from "react-intl";
import {useSearchParams} from "react-router-dom";
import {useContext} from "react";
import {LocaleContext} from "../context/LocaleContext";
import useNavigateParams from "../hooks/useNavigateParams";

const ERROR_PROFILE_ALREADY_LINKED = 'profile_already_linked_error';
const ERROR_3RD_PARTY_RELINK = '3rd_party_relink_error';

interface Props {
    retryFlow?: () => void;
}

const UpcErrorRetry = (props: Props) => {
    const navigateParams = useNavigateParams();
    const [searchParams] = useSearchParams();
    const localeContext = useContext(LocaleContext);
    const errorType = searchParams.get('error');

    const retryFlow = () => {
        navigateParams(`/upc/${localeContext.locale}`, {
            game: searchParams.get('game') || '',
            'upc-locale': searchParams.get('upc-locale') || ''
        });
    };

    const retryFlowWithMicrosoftLogout = () => {
        (window as any).logoutUser(`/upc/${localeContext.locale}?game=${searchParams.get('game') || ''}&upc-locale=${searchParams.get('upc-locale') || ''}`, true)
    };

    const openCustomerSupport = () => {
        window.open("https://www.ubisoft.com/help", "_blank");
    };

    const openHelpArticle = (articleId: string) => {
        window.open(`https://www.ubisoft.com/help/article/${articleId}`, "_blank");
    };

    return (
        <div className="error">
            <img className="icon" src={errorIcon} alt="Error" />
            {
                [ERROR_3RD_PARTY_RELINK, ERROR_PROFILE_ALREADY_LINKED].indexOf(errorType || '') === -1 && <>
                    <h2><FormattedMessage id="page.error.500"/></h2>
                    <button className="btn" onClick={props.retryFlow ? props.retryFlow : retryFlow}><FormattedMessage id="button.retry"/></button>
                </>
            }
            {
                [ERROR_3RD_PARTY_RELINK, ERROR_PROFILE_ALREADY_LINKED].indexOf(errorType || '') !== -1 && <>
                    <h2><FormattedMessage id="page.error.edge"/></h2>
                    <p><FormattedMessage id="page.error.edge.description"/></p>
                    <button className="btn" onClick={() => openHelpArticle('000101377')}><FormattedMessage id="button.read_faq"/></button>
                    <button className="btn" onClick={openCustomerSupport}><FormattedMessage id="button.cta.contact_support"/></button>
                    <p><FormattedMessage id="page.error.message.retry"/><button className="link" onClick={retryFlowWithMicrosoftLogout}><FormattedMessage id="page.error.message.retry.link"/></button></p>
                </>
            }
        </div>
    );
};

export default UpcErrorRetry;