import userIcon from "../resources/images/FlowIcons/user-icon.svg";
import { FormattedMessage } from "react-intl";

interface Props {
	loginAction: () => void;
}

const Login = (props: Props) => {
	return (
		<>
			<img className="icon" src={userIcon} alt="Login" />
			<h2>
				<FormattedMessage id="button.login.xbox" />
			</h2>
			<h5>
				<FormattedMessage id="page.login.subtitle.web" />
			</h5>
			<button className="btn btn-primary" onClick={props.loginAction}>
				<FormattedMessage id="button.login.web" />
			</button>
			<span className="disclaimer">
				<FormattedMessage
					id="page.activation.agreement"
					values={{
						ubisoftTermsLink: (
							<a
								className="link"
								href="https://legal.ubi.com/termsofuse"
								target="_blank"
								rel="noreferrer"
							>
								<FormattedMessage id="page.activation.agreement.link" />
							</a>
						),
						helpArticlesLink: (
							<a
								className="link"
								href="https://www.ubisoft.com/help/article/000100613"
								target="_blank"
								rel="noreferrer"
							>
								<FormattedMessage id="page.activation.agreement.help_articles.link.web" />
							</a>
						),
					}}
				/>
			</span>
		</>
	);
};

export default Login;
