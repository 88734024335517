import React, {useContext} from "react";
import ubiLogo from "../resources/images/logos/ubi-logo.svg";
import {Environment} from "../data/types/Environment";
import WebAuth from "../containers/auth/WebAuth";
import {LocaleContext} from "../context/LocaleContext";
import {PlatformContext} from "../context/PlatformContext";

const Navbar = () => {
    const localeContext = useContext(LocaleContext);
    const platformContext = useContext(PlatformContext);

    return (
        <nav className="navbar">
            <img className="logo" src={ubiLogo} alt="Ubisoft" />
            <WebAuth
                applicationId={process.env.REACT_APP_APPLICATION_ID!}
                genomeId={process.env.REACT_APP_GENOME_ID!}
                environment={process.env.REACT_APP_ENVIRONMENT! as Environment}
                locale={localeContext.locale}
                platform={platformContext.platform}
            />
        </nav>
    );
}

export default Navbar;